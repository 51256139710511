import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import ImageBlock from "../../../components/image-block/"
import SliderBlock from "../../../components/slider-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const StablesPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			image01: file(relativePath: { eq: "properties/the-stables/the-stables-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			image02: file(relativePath: { eq: "properties/the-stables/the-stables-02.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			image03: file(relativePath: { eq: "properties/the-stables/the-stables-03.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			image04: file(relativePath: { eq: "properties/the-stables/the-stables-04.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			image05: file(relativePath: { eq: "properties/the-stables/the-stables-05.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			image06: file(relativePath: { eq: "properties/the-stables/the-stables-06.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			image07: file(relativePath: { eq: "properties/the-stables/the-stables-07.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="The Stables"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/w-lamb/current-developments`}/>
				<Split>
					<SliderBlock
						background={ data.image01.childImageSharp.fluid }>
						<ImageBlock 
							image={ data.image01.childImageSharp.fluid }/>
						<ImageBlock 
							image={ data.image02.childImageSharp.fluid }/>
						<ImageBlock 
							image={ data.image03.childImageSharp.fluid }/>
						<ImageBlock 
							image={ data.image04.childImageSharp.fluid }/>
						<ImageBlock 
							image={ data.image05.childImageSharp.fluid }/>
						<ImageBlock 
							image={ data.image06.childImageSharp.fluid }/>
						<ImageBlock 
							image={ data.image07.childImageSharp.fluid }/>
					</SliderBlock>
					<TextBlock 
						title={`The Stables`}
						titleTag={ `h1` }
						text={`
							<h2>Hastoe, Hertfordshire</h2>
							<p>Four brand new luxury homes in an exclusive development built as a joint venture with Laxton Properties.</p>
							<a href="http://www.laxtonproperties.co.uk/project/the-stables-hastoe/" target="_blank">laxtonproperties.co.uk/the-stables</a>
						`}
						bgColour={wlColour.slug}/>

				</Split>
			</Layout>
		</>
	)
}

export default StablesPage
